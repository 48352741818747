
/*import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));*/
import es  from '@angular/common/locales/es';
import { AppRoutes } from './app/app.routing';
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { provideRouter } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorCatchingInterceptorFn } from '@core/interceptors/error-catching-fn.interceptor';
import { TokenInterceptorFn } from '@core/interceptors/token-fn.interceptor';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import {  provideNgxMask } from 'ngx-mask';
import { provideLottieOptions } from 'ngx-lottie';
import { GlobalHttpErrorHandler } from '@core/interceptors/global-http-error-handler.interceptor';


registerLocaleData(es);

  bootstrapApplication(AppComponent, {
   providers:[
     provideNgxMask(),
     provideLottieOptions({
      player: () => import('lottie-web')
     }),

    { provide: NZ_I18N, useValue: es_ES },

    provideRouter(AppRoutes),

    provideHttpClient(
      withInterceptors([
        ErrorCatchingInterceptorFn,
        TokenInterceptorFn
      ]),
    ),

    importProvidersFrom([HttpClientModule,BrowserAnimationsModule,BrowserModule])
   ]
  }).catch(err => console.error(err))
