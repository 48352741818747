import { Component, inject, OnInit, ChangeDetectorRef, HostListener, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CollapsibleMenuComponent } from '@components/collapsible-menu/collapsible-menu.component';
import { ModalService } from '@components/modal/modal.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PermissionsService } from '@services/permissions.service';
import { UpdateOnScrollDirective } from '@shared/directives/update-on-scroll.directive';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Subject, Subscription, startWith, switchMap, timer } from 'rxjs';
import { fadeAnimation } from 'src/app/utilities/router-outlet-animation';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports:[
    CollapsibleMenuComponent,
    UpdateOnScrollDirective,
    FontAwesomeModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NzLayoutModule,
    NgIf
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeAnimation],
  providers:[
    ModalService,
    NzModalService,
    NzNotificationService,
  ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('templateNotification') notificationRef!: TemplateRef<{}>;
  @ViewChild('customCloseIcon') customCloseIcon!: TemplateRef<void>;

  private readonly modalSvc = inject(ModalService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly permissionsSvc = inject(PermissionsService);
  private readonly notification = inject(NzNotificationService);

  private reset$ = new Subject();
  private idleTime$!: Observable<number>;
  private idleTimeSub$!: Subscription;

  userData: {initial: string, name: string} = {initial: '', name: ''};
  menuData: {parameter: string, url: string}[] = [];

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();

    this.initialize();
    this.idleTimeSub$ = this.idleTime$.subscribe((val) => {

      //! si el tiempo ( en segundos ) llega a 25 minutos avisa que se cerrará la aplicacion
      if( val == 1500 ){
        this.notification.template(
          this.notificationRef,
          {
            nzPlacement: 'top',
            nzDuration: 0,
            nzClass: 'custom-notification-error-large',
            nzCloseIcon: this.customCloseIcon,
          }
        );
      }

      //! si el tiempo ( en segundos ) llega a media hora hace un logout
      if(val == 1800){
        this.refreshTimer();
        this.permissionsSvc.logout();
      }
    });

    this.userData = this.permissionsSvc.getUserData;
    this.menuData = this.permissionsSvc.getMenuData;
  }

  ngOnDestroy(): void {
    this.reset$.unsubscribe();
    this.idleTimeSub$.unsubscribe();
  }

  initialize(): void {
    this.idleTime$ = this.reset$.pipe(
      startWith(void 0),
      switchMap(() => timer(1000, 1000))
    );
  }

  refreshTimer(): void {
    this.reset$?.next(void 0);
  }

  @HostListener('mousemove', ['$event'])
    onMousemove(_: MouseEvent) {
      this.refreshTimer();
    }

  @HostListener('mousedown', ['$event'])
    onMousedown(_: MouseEvent) {
      this.refreshTimer();
    }

  @HostListener('window:load', ['$event'])
    onLoad(_: Event) {
      this.refreshTimer();
    }

  @HostListener('click', ['$event'])
    onClick(_: MouseEvent) {
      this.refreshTimer();
    }

  @HostListener('document:keydown', ['$event'])
    onKeydown(_: Event) {
      this.refreshTimer();
    }

  @HostListener('touchstart', ['$event'])
    onTouchStart(_: Event): void {
      this.refreshTimer();
    }

  lastScrollTop: any;

  collapse: boolean = true;

  collapsibleMenus = [
    {
      id: 0,
      isOpen: false,
    },
    {
      id: 1,
      isOpen: false,
    },
    {
      id: 2,
      isOpen: false,
    },
    {
      id: 4,
      isOpen: false,
    },
  ];

  accordionBehavior(collapsibleIndex: number){
    collapsibleIndex = Number(collapsibleIndex);

    this.collapsibleMenus.forEach((item) => {
      item.id == collapsibleIndex
      ? item.isOpen = true
      : item.isOpen = false;
    })
  }

  closeAllCollapsibleAreas(){
    this.collapsibleMenus.forEach((item) => {
      item.isOpen = false;
    })
  }

  modalTest(){
    const modalRef = this.modalSvc.welcomeModal();

    setTimeout(() => {
      modalRef.close();
    }, 3900);
  }

  logout(){
    this.permissionsSvc.logout();
  }

  optionExists(option: string){

    const itExist = this.menuData.some( element => {
      return element.parameter == option;
    });

    return itExist;
  }
}
