<div>
  <div class="flex justify-between cursor-pointer py-[10px]" (click)="isOpen = !isOpen; activeMenu()">
    <span class="flex">
      <ng-content select="[menu-icon]"></ng-content>
      <p class="font-sans-bold text-14 ml-[1.5rem] menu-title" >{{ title }}</p>
    </span>
    <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="{'active': isOpen}" class="menu-arrow"></fa-icon>
  </div>
  <div class="wrapper" [ngClass]="{'is-open': isOpen}">
    <div class="inner">
      <ng-content select="[menu-content]" ></ng-content>
    </div>
  </div>
</div>
