import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { NzConfigService } from 'ng-zorro-antd/core/config';

registerLocaleData(es);
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],

  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'core-fluens';

  constructor(
    library: FaIconLibrary,
    private readonly nzConfigService: NzConfigService
  ) {
    // Add multiple icons to the library
    library.addIconPacks(fal, fas);

    this.nzConfigService.set('dropDown', { nzBackdrop: true });
  }

  ngOnInit(): void {
  }

}
