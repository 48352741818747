import { Injectable } from '@angular/core';
import { HttpRequestService } from './api/http-request.service';
import { environment } from '../../environments/environment';
import { RequestResponse } from './api/interfaces/request.interface';
import { Observable } from 'rxjs';
import { transformJsonToFormData } from '../utilities/transform-json-to-form-data';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService extends HttpRequestService {

  private url = "auth";

  constructor() {
    super();
    this.getToken();
  }

  public postTokenClient(body: { username: string; password: string; }): Observable<RequestResponse>{
    const clientBody = {
      ...body,
      api_username: environment.api_username,
      api_password: environment.api_password
    }

    return this.post(`${this.url}/login/client`, transformJsonToFormData(clientBody));
  }

  public postSendOtp(body: {id: string; type_otp: string; }): Observable<RequestResponse>{
    return this.post(`${this.url}/login/otp/send`, transformJsonToFormData(body));
  }

  public postValidateOtp(body: {user_id: string; code: string; }): Observable<RequestResponse>{
    return this.post(`${this.url}/login/otp/validate`, transformJsonToFormData(body));
  }

  public saveToken(token: string){
    localStorage.setItem('token', token);
  }

  public getToken(){
    return localStorage.getItem('token');
  }

  public cleanUpToken(){
    localStorage.removeItem('token');
  }

  public override logout(){
    localStorage.clear();
    this.router.navigate(['/auth/iniciar-sesion'])
  }

  //! Permisos
  public postPermissions(body: {url: string}): Observable<RequestResponse>{
    return this.post(`permission/search/user`, transformJsonToFormData(body));
  }

  parameters: {action: string; parameter: string; url: string}[] = [];

  public set setParameters(body: {action: string; parameter: string; url: string}[]){
    this.parameters = body;
    // console.log(this.parameters);
  }

  public get getParameters(){
    return this.parameters;
  }

  userData: {initial: string, name: string} = {initial: '', name: ''};

  public set setUserData(data: {initial: string, name: string}){
    this.userData = data;
  }

  public get getUserData(){
    return this.userData;
  }

  menuData: {parameter: string, url: string}[] = [];

  public set setMenuData(data: {parameter: string, url: string}[]){
    this.menuData = data;
  }

  public get getMenuData(){
    return this.menuData;
  }

  public parameterExists (option: string) {
    const itExist = this.getParameters.some( element => {
      return element.parameter == option;
    });

    return itExist;
  }
}
