import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { RequestOptions, RequestResponse } from './interfaces/request.interface';

import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  protected readonly http = inject(HttpClient);
  protected readonly api = environment.API_URL;
  protected readonly router = inject(Router);

  public get<T>(endpoint: string, options?: RequestOptions): Observable<T> {
    return this.http.get<T>(`${this.api}${endpoint}`, options)
                    .pipe( map(res => {
                      const code = (res as RequestResponse).code

                      if( code == 401 ){
                        this.logout();
                      }

                      return res
                    }));
  }

  public getFile(endpoint: string): Observable<Blob> {
    return this.http.get(`${this.api}${endpoint}`, {responseType: 'blob'});
  }

  public post<T>(endpoint: string, body: any, options?: RequestOptions): Observable<T> {
    return this.http.post<T>(`${this.api}${endpoint}`, body, options)
                    .pipe( map(res => {
                      const code = (res as RequestResponse).code

                      if( code == 401 ){
                        this.logout();
                      }

                      return res
                    }));
  }

  public put<T>(endpoint: string, body: any, options?: RequestOptions): Observable<T> {
    return this.http.put<T>(`${this.api}${endpoint}`, body, options)
                    .pipe( map(res => {
                      const code = (res as RequestResponse).code

                      if( code == 401 ){
                        this.logout();
                      }

                      return res
                    }));
  }

  public logout(){
    localStorage.clear();
    this.router.navigate(['/auth/iniciar-sesion'])
  }
}
