import { LoadingComponent } from '@Pages/layout/components/loading/loading.component';
import { WelcomeComponent } from '@Pages/layout/components/welcome/welcome.component';
import { Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';

import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modal: NzModalService) {}

  create(NzModalCustomComponent: any, data: Object = {}, width: string = '500px', center: boolean = true) {
    this.modal.closeAll();

    const modal = this.modal.create({
      nzContent: NzModalCustomComponent,
      nzData: data,
      nzClosable: false,
      nzCentered: center,
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: width
    });

    return modal;
  }

  loadingModal() {
    this.modal.closeAll();

    const modal = this.modal.create({
      nzContent: LoadingComponent,
      nzClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: '400px',
      nzClassName: 'loading-modal',
      nzWrapClassName: 'loading-modal-content',
    });

    return modal;
  }

  welcomeModal() {
    this.modal.closeAll();

    const modal = this.modal.create({
      nzContent: WelcomeComponent,
      nzClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: '600px',
      nzClassName: 'welcome-modal',
      nzWrapClassName: 'welcome-modal'
    });

    return modal;
  }

  guide(NzModalCustomComponent: any, data: Object = {}, width: string = '500px', center: boolean = true) {
    this.modal.closeAll();

    const modal = this.modal.create({
      nzContent: NzModalCustomComponent,
      nzData: data,
      nzClosable: false,
      nzCentered: center,
      nzFooter: null,
      nzMaskClosable: true,
      nzKeyboard: true,
      nzWidth: width,
      nzClassName: 'class-1',
      nzWrapClassName: 'ant-modal-wrap-guide'
    });

    return modal;
  }
}
