import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { catchError, throwError, retry, timer } from 'rxjs';
import { getValidationError } from '@core/utilities/get-validation-error';

export const ErrorCatchingInterceptorFn: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {

  //* Si existe el token en el localStorage lo agregará a las peticiones
  const modifiedReq  = request.clone({
    ...( localStorage.getItem('token')
    && {headers: request.headers.set('authorization', `Bearer ${String(localStorage.getItem('token'))}`  )}
    )
  });

  return next(modifiedReq ).pipe(
    //* En caso de fallar la peticion la reintentará con una variacion en el tiempo
    retry({
      count: 2,
      delay: (_, retryCount) => timer(retryCount * 100),
    }),
    catchError( (err: HttpErrorResponse ) => {
      //* Capturará el error y mostrará un mensaje dependiendo del tipo error
      console.error(getValidationError(err.status));
      return throwError(() => getValidationError(err.status));
    })
  );
}
