import {  Routes } from '@angular/router';
import { LayoutComponent } from '@Pages/layout/layout.component';
import { authGuard } from '@core/guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import ('@Pages/layout/layout.routing').then((r) => r.LayoutRoutes),
  },
  {
    path: 'auth',
    loadChildren: () => import('@Pages/auth/iniciar-sesion-empresa/iniciar-sesion-empresa.routing').then((r) => r.IniciarSesionEmpresaRouting),
  },

  {
    path: '**',
    redirectTo: '/auth/iniciar-sesion',
    pathMatch: 'full',
  },

];


