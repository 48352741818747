import { inject } from '@angular/core';
import { HttpContext, HttpContextToken, HttpHandler, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { AuthService } from "@services/auth.service";
import { TokenService } from "@services/token.service";
import { Observable, switchMap } from "rxjs";

const CHECK_TOKEN = new HttpContextToken<boolean>(() => false);

export function checkToken() {
  return new HttpContext().set(CHECK_TOKEN, true);
}

export const TokenInterceptorFn: HttpInterceptorFn = (req: any, next: any) => {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);
    if (req.context.get(CHECK_TOKEN)) {
      const isValidToken = tokenService.isValidToken(); // accessToken
      if (isValidToken) {
        return addToken(req, next);
      } else {
        return updateAccessTokenAndRefreshToken(req, next);
      }
    }
    return next(req);
}


function addToken(request: HttpRequest<unknown>, next: HttpHandler) {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);
  const accessToken = tokenService.getToken();
  if (accessToken) {
    const authRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
    });
    return next.handle(authRequest);
  }
  return next.handle(request);
}

function updateAccessTokenAndRefreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);

  const refreshToken = tokenService.getRefreshToken();
  const isValidRefreshToken = tokenService.isValidRefreshToken();
  if (refreshToken && isValidRefreshToken) {
    return authService.refreshToken(refreshToken)
    .pipe(
      switchMap(() => addToken(request, next)),
    )
  }
  return next.handle(request);
}
