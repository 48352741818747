import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    CommonModule,
    LottieComponent
  ],
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent {

  lottieOptions: AnimationOptions = {
    path: '/assets/animated/CorefluensUltimo.json',
  }

}
