// sub proceso de transformar un json a FormData (recursiva)
const recursiveJson = (key:string, json:any) => {
    // validar si es un "object"
    if (json && typeof json == "object" && !(json instanceof Date)) {
        // validar si es un "array"
        if (Array.isArray(json)) {
            // variable de respuesta
            let resp:any[] = []
            // recorrer "array"
            json.forEach((e:any, index:number) => {
                // respuesta en { "key": key-index, "value": value }
                const resp2 = recursiveJson(key + '-' + index, e);
                // recorrer "array" con relacion [key]:[value]
                if (Array.isArray(resp2)) resp2.forEach(e3 => { resp.push(e3) });
                // obtener respuestas relacion [key]:[value]
                else resp.push(resp2)
            });
            // respuesta en "array"
            return resp;
        } else {
            // variable de respuesta
            let resp:any[] = []
            // recorrer "object"
            Object.keys(json).forEach(key2 => {
                // respuesta en { "key": key-key2, "value": value }
                const resp2 = recursiveJson(key + '-' + key2, json[key2]);
                // recorrer "array" con relacion [key]:[value]
                if (Array.isArray(resp2)) resp2.forEach(e3 => { resp.push(e3) });
                // obtener respuestas relacion [key]:[value]
                else resp.push(resp2)
            });
            // respuesta en "array"
            return resp;
        }
    } else return { "key": key, "value": typeof json == 'boolean' ? ( json ? 1 : 0) : (json ?? '') };
}
// transformar un json a FormData
export const transformJsonToFormData = (json: any) => {
    // crear un FormData
    const formData = new FormData();
    // recorrer array object
    Object.keys(json).forEach(key => {
        // respuesta en { "key": key, "value": value }
        const resp = recursiveJson(key, json[key]);
        // obtener respuestas que estan en arrays
        if (Array.isArray(resp)){
          resp.forEach(e => {
            formData.append( e['key'], e['value'] );
          });
        }
        // obtener respuestas relacion [key]:[value]
        else {
          formData.append( resp['key'], resp['value'] );
        }
    });
    // retornar resultados en FormData
    return formData;
};

