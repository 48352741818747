import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  standalone: true,
  imports: [
    LottieComponent
  ]
})
export class LoadingComponent  {

  lottieOptions: AnimationOptions = {
    path: '/assets/animated/corefluens/loading.json',
  }

}
