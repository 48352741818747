import { TypeWithKey } from "@core/models/type-with-key.type";

export const getValidationError = (errorCode: number | string) => {
  //* Se definen los mensajes para los estados de error
  const codeMatcher: TypeWithKey<string> = {
    400: '¡Ups! Algo salió mal. Por favor, verifica tu solicitud',
    401: '¡Acceso denegado! Por favor, inicia sesión o verifica tus credenciales',
    402: '¡Atención! Se requiere un pago para continuar',
    403: '¡Lo siento, no tienes permitido el acceso aquí!',
    404: '¡Ups! No podemos encontrar lo que buscas',
    405: '¡Ese método no es válido aquí!',
    406: 'Lo sentimos, no podemos mostrar el contenido en este formato',
    407: 'Por favor, proporciona las credenciales para acceder a través del proxy',
    408: '¡Vaya! La solicitud ha tardado demasiado. Intenta de nuevo más tarde',
    409: '¡Algo está chocando aquí! Existe un conflicto en la solicitud',
    410: '¡Este recurso se ha ido! Ya no está disponible',
    411: '¡Ups! Falta la longitud del contenido en la solicitud',
    412: '¡Oops! No se cumplieron las condiciones para esta solicitud',
    413: '¡Oh no! La carga de datos es demasiado grande',
    414: '¡La URL es demasiado larga! Por favor, acórtala',
    415: 'Lo sentimos, pero el tipo de medio no es compatible',
    416: 'La gama de datos solicitada no es satisfactoria',
    417: '¡Vaya! La expectativa en la cabecera no se cumplió',
    418: '¡Ups! Parece que te topaste con una tetera de broma',
    421: '¡Algo salió mal con la solicitud!',
    422: 'Lo siento, pero no podemos procesar la entidad',
    423: '¡Este recurso está bloqueado!',
    424: 'La solicitud depende de otra acción que falló',
    425: '¡Es demasiado temprano para esta solicitud!',
    426: '¡Es hora de actualizar!',
    428: 'Se necesita una condición previa para esta solicitud',
    429: '¡Demasiadas solicitudes! Por favor, espera un poco',
    431: '¡Vaya! Los campos de cabecera son demasiado grandes',
    451: 'Lo sentimos, pero este recurso no está disponible por razones legales',
    500: '¡Oh no! Algo salió mal en nuestro servidor',
    501: '¡Esta función aún no está implementada!',
    502: '¡Vaya! Hay un problema con el servidor intermedio',
    503: 'Lo sentimos, pero el servicio no está disponible en este momento',
    504: '¡El tiempo de espera del servidor intermedio ha expirado!',
    505: 'La versión de HTTP no es compatible con la solicitud',
    506: 'El servidor ha detectado una referencia circular en las negociaciones de variantes',
    507: '¡Vaya! El servidor no tiene suficiente espacio de almacenamiento',
    508: 'Se ha detectado un bucle en la solicitud',
    510: 'La solicitud necesita más extensiones para completarse',
    511: 'Se requiere autenticación de red para continuar',
  };

  return codeMatcher[errorCode];
};
