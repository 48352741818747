import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'core-collapsible-menu',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  templateUrl: './collapsible-menu.component.html',
})
export class CollapsibleMenuComponent {
  @Input() id: number = 0;
  @Input() title: string = '';
  @Input() isOpen: boolean = false;

  @Output() activeMenuEvent  = new EventEmitter<number>();

  activeMenu(){
    this.activeMenuEvent.emit(this.id);
  }
}
