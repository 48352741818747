import { Directive, inject, ElementRef, OnInit, HostListener } from '@angular/core';
import { NzSelectComponent } from 'ng-zorro-antd/select';

@Directive({
  selector: '[updateOnScroll]',
  standalone: true
})
export class UpdateOnScrollDirective {
  private readonly elemtentRef = inject(ElementRef);
  lastScrollTop: any;

  @HostListener('scroll') updateOnScroll(){
    let overlaypanes:any = document.getElementsByClassName("cdk-overlay-pane");
    let overlaypanesconnected:any = document.getElementsByClassName("cdk-overlay-connected-position-bounding-box");
    let amountScrolled = this.lastScrollTop - this.elemtentRef.nativeElement.scrollTop;
    this.lastScrollTop = this.elemtentRef.nativeElement.scrollTop;

    if(overlaypanes.length > 0 && overlaypanesconnected.length == 0){
      for (let index = 0; index < overlaypanes.length; index++) {
        const overlaypane = overlaypanes[index];
        const paneTop = overlaypane.style.top;
        const paneBottom = overlaypane.style.bottom;

        // * Arreglar el posicionamiento del overlaypane principalmente el de dropdown
        overlaypane.style.position = 'absolute';

        const isTooltipOverlay = overlaypane.innerHTML.includes('ant-tooltip');
        const isDropdownOverlay = overlaypane.innerHTML.includes('ant-dropdown');

        if( !isDropdownOverlay ){
          if( (paneTop != 0 || paneTop == '') && !isTooltipOverlay ){
            overlaypane.style.top = Number(paneTop.replace("px", "")) + amountScrolled +"px";
          }
          if(paneBottom != 0 || paneBottom == '' ){
            overlaypane.style.bottom = Number(paneBottom.replace("px", "")) + (amountScrolled * -1) +"px";
          }
        }
      }
    }

    if(overlaypanesconnected.length > 0){
      for (let index = 0; index < overlaypanesconnected.length; index++) {
        const overlaypane = overlaypanesconnected[index];
        const paneTop = overlaypane.style.top;
        const paneBottom = overlaypane.style.bottom;

        if( (paneTop != 0 || paneTop == '') ){
          overlaypane.style.top = Number(paneTop.replace("px", "")) + amountScrolled +"px";
        }
        if(paneBottom != 0 || paneBottom == '' ){
          overlaypane.style.bottom = Number(paneBottom.replace("px", "")) + (amountScrolled * -1) +"px";
        }
      }
    }
  }

}
